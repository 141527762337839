import axios from "axios";
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '@/store';

// Optimized cache implementation
const cache = new Map();
const backgroundUpdates = new Map();

const getCacheKey = (config) => {
  const { url, method, data } = config;
  return `${method}-${url}-${JSON.stringify(data || {})}`;
};

const setCacheData = (key, response, maxAge = 15 * 60 * 1000) => {
  cache.set(key, {
    data: response,
    timestamp: Date.now(),
    maxAge
  });
};

const getCacheData = (key) => {
  const entry = cache.get(key);
  if (!entry) return null;
  
  const isStale = Date.now() - entry.timestamp > entry.maxAge;
  return {
    ...entry.data,
    isStale,
    fromCache: true
  };
};

const baseurlapi = window.location.href.includes('localhost')
? 'https://hms.deepcodegroup.com/api/apiv1/'
: 'https://adminapi.ghms360.com/api/apiv1/';
// Create axios instance with performance-optimized config
const axiosInstance = axios.create({
  baseURL: baseurlapi,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded',
    'crossDomain': true,
    'Accept-Encoding': 'gzip, deflate, br'
  }
});

// Optimized progress bar configuration
Nprogress.configure({ 
  minimum: 0.1,
  showSpinner: false,
  speed: 200,
  trickleSpeed: 100
});

let requestsCounter = 0;
let progressTimeout;

// Request interceptor with enhanced caching
axiosInstance.interceptors.request.use(
  async (config) => {
    if (requestsCounter === 0) {
      if (progressTimeout) clearTimeout(progressTimeout);
      progressTimeout = setTimeout(() => Nprogress.start(), 50);
    }
    requestsCounter++;

    // Token handling
    const authuser = store.getters.authuser;
    if (authuser) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('hospitalmanagementsystem' + authuser.id);
    } else {
      config.headers.Authorization = 'Bearer ';
    }

    // Enhanced cache checking
    if (!config.skipCache) {
      const cacheKey = getCacheKey(config);
      const cachedResponse = getCacheData(cacheKey);
      
      if (cachedResponse && !cachedResponse.isStale) {
        requestsCounter--;
        if (requestsCounter === 0) {
          if (progressTimeout) clearTimeout(progressTimeout);
          Nprogress.done();
        }
        return Promise.resolve(cachedResponse);
      }

      // If we have stale data, return it but fetch fresh data in background
      if (cachedResponse && cachedResponse.isStale && !backgroundUpdates.has(cacheKey)) {
        backgroundUpdates.set(cacheKey, true);
        
        // Clone the config for background request
        const backgroundConfig = { ...config, skipCache: true };
        
        setTimeout(() => {
          axiosInstance(backgroundConfig)
            .then(freshResponse => {
              setCacheData(cacheKey, freshResponse);
              // If the response contains data that updates store state,
              // it will happen automatically through the original request pattern
            })
            .finally(() => {
              backgroundUpdates.delete(cacheKey);
            });
        }, 0);
      }
    }

    config.requestId = Math.random().toString(36).slice(7);
    return config;
  },
  (error) => {
    requestsCounter--;
    if (requestsCounter === 0) {
      if (progressTimeout) clearTimeout(progressTimeout);
      Nprogress.done();
    }
    return Promise.reject(error);
  }
);

// Response interceptor with caching
axiosInstance.interceptors.response.use(
  (response) => {
    requestsCounter--;
    if (requestsCounter === 0) {
      if (progressTimeout) clearTimeout(progressTimeout);
      Nprogress.done();
    }

    // Cache successful responses
    if (!response.config.skipCache) {
      const cacheKey = getCacheKey(response.config);
      setCacheData(cacheKey, response);
    }

    return response;
  },
  async (error) => {
    requestsCounter--;
    if (requestsCounter === 0) {
      if (progressTimeout) clearTimeout(progressTimeout);
      Nprogress.done();
    }

    if (!error.response) {
      console.error('Network Error:', error);
      throw new Error('Network error occurred. Please check your connection.');
    }

    // if (error.response.status === 401) {
    //   localStorage.clear();
    //   window.location.href = '/auth/login';
    //   return Promise.reject(error);
    // }

    // Optimized retry logic
    const { config } = error;
    if (!config || !config.retry) {
      return Promise.reject(error);
    }

    config.retryCount = config.retryCount || 0;

    if (config.retryCount >= config.retry) {
      return Promise.reject(error);
    }

    config.retryCount += 1;
    const backoffDelay = Math.min(
      config.retryDelay * Math.pow(1, config.retryCount - 1),
      30000 // Max delay of 30 seconds
    );
    
    await new Promise(resolve => setTimeout(resolve, backoffDelay));
    return axiosInstance(config);
  }
);

// Cache management methods
axiosInstance.clearCache = () => {
  cache.clear();
  backgroundUpdates.clear();
};

axiosInstance.removeCacheEntry = (config) => {
  const cacheKey = getCacheKey(config);
  cache.delete(cacheKey);
  backgroundUpdates.delete(cacheKey);
};

// Global configuration
window.axios = axiosInstance;

axiosInstance.defaults.retry = 1;
axiosInstance.defaults.retryDelay = 1000;
axiosInstance.defaults.timeoutErrorMessage = 'Request timed out. Please try again.';

const CancelToken = axios.CancelToken;
axiosInstance.cancelToken = CancelToken;

export default axiosInstance;