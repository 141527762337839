export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
import { useRouter } from 'vue-router';
import { comp_play_error_file, comp_play_success_file } from './utils';
const toast = useToast()
const router = useRouter();

export default {
    state : {
        authuser :'',
        authlocation : '',
        isAuth:'',
		returnvalue:'',
        userip:'',
        clientlocation:'',
        clientdevice:'',
        hospital:'',
        userSessionTimerStatus:false,
    },
    
    getters : {
        authuser: (state )=> state.authuser,
        authlocation: (state )=> state.authlocation,
		returnvalue: (state )=> state.returnvalue,
        isAuth: state => !!state.authuser,
        userip: (state )=> state.userip,
        clientlocation:(state )=> state.clientlocation,
        clientdevice:(state )=> state.clientdevice,
        hospital:(state )=> state.hospital,
        userSessionTimerStatus:(state )=> state.userSessionTimerStatus,
    },


    mutations : { 
        setauthuser :(state,authuser ) => (state.authuser = authuser), 
        setauthlocation :(state,authlocation ) => (state.authlocation = authlocation), 
        setisAuth :(state,isAuth ) => (state.isAuth = isAuth), 
        setreturnvalue :(state,returnvalue ) => (state.returnvalue = returnvalue),
        setuserip :(state,userip ) => (state.userip = userip),
        setclientlocation :(state,clientlocation ) => (state.clientlocation = clientlocation),
        setclientdevice :(state,clientdevice ) => (state.clientdevice = clientdevice),
        sethospital :(state,hospital ) => (state.hospital = hospital),
        setuserSessionTimerStatus :(state,userSessionTimerStatus ) => (state.userSessionTimerStatus = userSessionTimerStatus),
    },

    actions : {


        getuseripaddress({ commit }){
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    commit('setuserip', ip)
                });
        },

        getuserlocation({ commit, getters }){
            console.log(getters.userip)
            const url = "https://ipapi.co/"+ getters.userip +"/json/";
            const options = {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                a: 10,
                b: 20,
            }),
            };
            fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                commit('setclientlocation', data)
            });
        },


        async register( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'register')
            return new Promise((resolve, reject) => {
            axios.post("/auth/register", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    commit('setuserSessionTimerStatus', true)
                    toast.success(response.data.msg)
                    resolve(response)
                 
                }  
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async login( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'login')
            return new Promise((resolve, reject) => {
            axios.post("/auth/login", data)
            .then(async response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    commit('setuserSessionTimerStatus', true)
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async forgot_password( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/forgot_password", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async reset_password( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/reset_password", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async request_otp( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/auth/requestotp", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async verify_code( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'verify_code')
            return new Promise((resolve, reject) => {
            axios.post("/auth/verify_code", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    commit('setuserSessionTimerStatus', true)
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },


        async getauthuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'loginuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/authuser", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    commit('setuserSessionTimerStatus', true)
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },


        async logoutuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'logoutuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/logout", data)
            .then(response => {	
                console.log(response);
                commit('setloader', false)
                localStorage.clear()
                commit('setauthuser', '')
                commit('setuserSessionTimerStatus', false)
                toast.success(response.data.msg)
                router.push({name:'login'});
                resolve(response)

            })
            .catch(async error=> {
                commit('setloader', false)
                localStorage.removeItem('hospitalmanagementsystem')
                commit('setauthuser', '')
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                router.push({name:'login'});
                await comp_play_error_file(); 
               
                reject(error)
            });
            });	       
        },


        async update_user_account( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_user_account')
            return new Promise((resolve, reject) => {
            axios.post("/auth/updateaccount", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    commit('setuserSessionTimerStatus', true)
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },



        async changepassword( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'changepassword')
            return new Promise((resolve, reject) => {
            axios.post("/auth/updatepassword", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    commit('setuserSessionTimerStatus', true)
                    toast.success(response.data.msg)
                    resolve(response)
                 
                } 
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                reject(error)
            });
            });	       
        },

        async deleteuseraccount( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'deleteuseraccount')
            return new Promise((resolve, reject) => {
            axios.post("/auth/deleteaccount", data)
            .then(response => {	
                commit('setloader', false)
                localStorage.clear()
                commit('setauthuser', '')
                commit('setuserSessionTimerStatus', false)
                toast.success(response.data.msg)
                router.push({name:'login'});
                resolve(response)
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                await comp_play_error_file(); 
                router.push({name:'login'});
                reject(error)
            });
            });	       
        },


        //create hospital
        async create_hospital( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_hospital')
            return new Promise((resolve, reject) => {
            axios.post("/auth/hospital/create", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.data){
                    localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                    commit('setauthuser', response.data.data)
                    commit('sethospital', response.data.data.hospital[0])
                    commit('setuserSessionTimerStatus', true)
                    toast.success(response.data.msg)
                    resolve(response)
                }  
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            await comp_play_error_file(error.response.data.msg); 
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
            
                reject(error)
            });
            });	       
        },


        //cleare status
        async session_timeout_status( { commit }, status) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setuserSessionTimerStatus', status)    
        },


        //auth methods
        // Methods to add to your auth store

        async updateUserProfile({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_user_profile')
            return new Promise((resolve, reject) => {
                axios.post("/auth/update_profile", data)
                .then(response => {
                    commit('setloader', false)
                    if(response.data){
                        toast.success('Profile updated successfully')
                        // If you store user data in Vuex
                        if(response.data.data) {
                            localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                            commit('setauthuser', response.data.data)
                            commit('sethospital', response.data.data.hospital[0])
                            commit('setuserSessionTimerStatus', true)
                            toast.success(response.data.msg)
                            resolve(response)
                        }
                    }
                    resolve(response)
                })
                .catch(async error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    await comp_play_error_file();
                    reject(error)
                });
            });
        },

        async updateUserProfileImage({ commit }, formData) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_user_profile')
            
            // Ensure we're using FormData for file upload
            const uploadData = new FormData()
            if (formData.profile_image) {
                uploadData.append('profile_image', formData.profile_image)
            }
            
            return new Promise((resolve, reject) => {
                axios.post("/auth/update_profile_image", uploadData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    commit('setloader', false)
                    if(response.data){
                        toast.success('Profile image updated successfully')
                        // Update user profile image if needed
                        if(response.data.data) {
                            console.log(response.data.data)
                            localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                            commit('setauthuser', response.data.data)
                            commit('sethospital', response.data.data.hospital[0])
                            commit('setuserSessionTimerStatus', true)
                        }
                    }
                    resolve(response)
                })
                .catch(async error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    await comp_play_error_file();
                    reject(error)
                });
            });
        },

        async updateEmployeeDetails({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_employee')
            return new Promise((resolve, reject) => {
                axios.post("/auth/update_employee", data)
                .then(response => {
                    commit('setloader', false)
                    if(response.data){
                        toast.success('Employee details updated successfully')
                        // If you need to update employee data in the store
                        if(response.data.data) {
                            localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                            commit('setauthuser', response.data.data)
                            commit('sethospital', response.data.data.hospital[0])
                            commit('setuserSessionTimerStatus', true)
                        }
                    }
                    resolve(response)
                })
                .catch(async error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    await comp_play_error_file();
                    reject(error)
                });
            });
        },

        async updateAppSettings({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_settings')
            return new Promise((resolve, reject) => {
                axios.post("/auth/update_settings", data)
                .then(response => {
                    commit('setloader', false)
                    if(response.data){
                        toast.success('App settings updated successfully')
                        // You might want to update app settings in Vuex if needed
                        if(response.data.data) {
                            localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                            commit('setauthuser', response.data.data)
                            commit('sethospital', response.data.data.hospital[0])
                        }
                    }
                    resolve(response)
                })
                .catch(async error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    await comp_play_error_file();
                    reject(error)
                });
            });
        },

        async updatePassword({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_password')
            return new Promise((resolve, reject) => {
                axios.post("/auth/update_password", data)
                .then(response => {
                    commit('setloader', false)
                    if(response.data){
                        localStorage.setItem('hospitalmanagementsystem'+ response.data.data.id, response.data.authtoken.accessToken)
                        commit('setauthuser', response.data.data)
                        commit('sethospital', response.data.data.hospital[0])
                        toast.success('Password updated successfully. Please log in again.')
                    }
                    resolve(response)
                })
                .catch(async error => {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    await comp_play_error_file();
                    reject(error)
                });
            });
        }

    },


    

    
}













