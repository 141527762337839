export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        patient_cases :'', 
        patient_case :'', 
        activepatient_cases:'',
    },
    
    getters : {
        patient_cases: (state )=> state.patient_cases,
        patient_case: (state )=> state.patient_case,
        activepatient_cases: (state )=> state.activepatient_cases,
    },


    mutations : { 
        setpatient_cases :(state,patient_cases ) => (state.patient_cases = patient_cases), 
        setpatient_case :(state,patient_case ) => (state.patient_case = patient_case), 
        setactivepatient_cases :(state,activepatient_cases ) => (state.activepatient_cases = activepatient_cases), 
    },

    actions : {

        async create_patient_case( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_patient')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    console.log(response.data.data);
                commit('setpatient_cases', response.data)
                toast.success("patient_case Added Successfully")
                }
                resolve(response)
                
            })
            .catch(async error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                
                reject(error)
            });
            });	       
        },


        async get_patient_cases( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_patient_cases')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpatient_cases', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_get_patient_cases_pusher( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')

            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases?page=" + page, data)
            .then(response => {	
                if(response.data){
                commit('setpatient_cases', response.data.data)
                }
                resolve(response)
            })
            .catch(error=> {
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_activepatient_cases( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_activepatient_cases')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/active?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setactivepatient_cases', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_patient_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_patient_case')
            commit('setpageloading', 'get_patient_case')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                }
                commit('setpageloading', '')
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                commit('setpageloading', '')
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_patient_case_mini( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_patient_case_mini')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_patient_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_patient_case')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpatient_cases', response.data.data)
                toast.success("patient_case Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async forward_patient_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'forward_patient_case')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/forward/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async diagnosis_patient_case( { commit }, {data, id}) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', 'diagnosis_patient_case')
                return new Promise((resolve, reject) => {
                axios.post("/dashboard/patient_cases/diagnosis/" + id, data)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data){
                    commit('setpatient_case', response.data.data)
                    }
                    resolve(response)
                    
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                            
                        }
                    }
                    reject(error)
                });
                });	       
            },


         async labtest_patient_case( { commit }, {data, id}) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', 'labtest_patient_case')
                return new Promise((resolve, reject) => {
                axios.post("/dashboard/patient_cases/labtest/" + id, data)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data){
                    commit('setpatient_case', response.data.data)
                    }
                    resolve(response)
                    
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                            
                        }
                    }
                    reject(error)
                });
                });	       
            },

        async prescribe_drug_patient_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'prescribe_drug_patient_case')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/prescribe/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async issue_drug_patient_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'issue_drug_patient_case'+ id)
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/issue_drug/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async unissue_drug_patient_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'unissue_drug_patient_case'+ id)
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/unissue_drug/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async offer_patient_case_discount( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'offer_patient_case_discount')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/discount/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async make_payment_patient_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'make_payment_patient_case')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/payments/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async remove_pres_drug_patient_case( { commit }, {data, id}) {
                commit('seterror', '')
                commit('setmsg', '')
                commit('setloader', 'remove_pres_drug_patient_case' + id)
                return new Promise((resolve, reject) => {
                axios.post("/dashboard/patient_cases/remove_pres_drug/" + id, data)
                .then(response => {	
                    commit('setloader', false)
                    if(response.data){
                    commit('setpatient_case', response.data.data)
                    }
                    resolve(response)
                    
                })
                .catch(error=> {
                    commit('setloader', false)
                    if (error.response) {
                        if(error.response.data){
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                            }
                            
                        }
                    }
                    reject(error)
                });
                });	       
            },

        async change_patient_case_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_patient_case_status')
            return new Promise((resolve, reject) => {
                axios.post(`/dashboard/patient_cases/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {

                        if (data.returndata == 'active') {
                            commit('setactivepatient_cases', response.data.data)
                        } else if(data.returndata == 'details') {
                            commit('setpatient_case', response.data.data)
                        }else{
                            commit('setpatient_cases', response.data.data)
                        }

                        if (data.status == 1) {
                            toast.success("Case Activated Successfully")
                        } else {
                            toast.success("Closed Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if(error.response.data.msg){
                                commit('seterror', error.response.data.msg)
                                comp_play_error_file(error.response.data.msg);
                            }else if(error.response.data.message){
                                commit('seterrors', error.response.data.message)
                                comp_play_error_file(error.response.data.message);
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async convert_to_full_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'convert_to_full_case')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/convert/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpatient_case', response.data.data)
                comp_play_delete_file('Case Converted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        async delete_patient_case( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_patient_case')
            return new Promise((resolve, reject) => {
            axios.post("/dashboard/patient_cases/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setactivepatient_cases', response.data.data)
                comp_play_delete_file('patient_case Deleted Successfully');
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    }


    
}