export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        finance_dash_data :'', 
        incomes :'', 
        expenses :'', 
        general_finance_ledger :'', 

    },
    
    getters : {
        finance_dash_data: (state )=> state.finance_dash_data,
        incomes: (state )=> state.incomes,
        expenses:(state )=> state.expenses,
        general_finance_ledger:(state )=> state.general_finance_ledger,

    },


    mutations : { 
        setfinance_dash_data :(state,finance_dash_data ) => (state.finance_dash_data = finance_dash_data), 
        setincomes:(state,incomes ) => (state.incomes = incomes), 
        setexpenses:(state,expenses ) => (state.expenses = expenses),
        setgeneral_finance_ledger:(state,general_finance_ledger ) => (state.general_finance_ledger = general_finance_ledger),
    },

    actions : {

        async get_finance_dash_data( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_finance_dash_data')
            commit('setpageloading', 'get_finance_dash_data')
            return new Promise((resolve, reject) => {
            axios.post("/finance/dash?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfinance_dash_data', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_income_data( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_income_data')
            commit('setpageloading', 'get_income_data')
            return new Promise((resolve, reject) => {
            axios.post("/finance/incomes?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setincomes', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_expenses_data( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_expenses_data')
            commit('setpageloading', 'get_expenses_data')
            return new Promise((resolve, reject) => {
            axios.post("/finance/expenses?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setexpenses', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_general_ledger_data( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_general_ledger_data')
            commit('setpageloading', 'get_general_ledger_data')
            return new Promise((resolve, reject) => {
            axios.post("/finance/ledger", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    console.log(response.data.data)
                commit('setgeneral_finance_ledger', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_finance_insurace_groups( { commit }, { data, page }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_finance_insurace_groups')
            commit('setpageloading', 'get_finance_insurace_groups')
            return new Promise((resolve, reject) => {
            axios.post("/finance/insurance?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('set_insurance_groups', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_finance_insurace_group( { commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_finance_insurace_group')
            commit('setpageloading', 'get_finance_insurace_group')
            return new Promise((resolve, reject) => {
            axios.post("/finance/insurance/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('set_insurance_group', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async pay_insurance_bills( { commit }, { data, id }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'pay_insurance_bills')
            commit('setpageloading', 'pay_insurance_bills')
            return new Promise((resolve, reject) => {
            axios.post("/finance/insurance/paybill/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                // commit('set_insurance_group', response.data.data)
                }
                commit('setpageloading', false)
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


    }


    
}