// chats.js
export const namespaced = true 
import { comp_play_error_file, comp_play_success_file, comp_play_delete_file} from './utils';
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        chats: '',
        current_chat: '',
        chat_messages: [],
    },
    
    getters : {
        chats: (state) => state.chats,
        current_chat: (state) => state.current_chat,
        chat_messages: (state) => state.chat_messages,
    },

    mutations : { 
        setchats: (state, chats) => (state.chats = chats),
        setcurrent_chat: (state, chat) => (state.current_chat = chat),
        setchat_messages: (state, messages) => (state.chat_messages = messages),
        addchat_messages: (state, messages) => {
            if (Array.isArray(messages)) {
                state.chat_messages = [...state.chat_messages, ...messages];
            } else {
                state.chat_messages.push(messages);
            }
        },
    },

    actions : {
        async create_new_chat({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_new_chat')
            return new Promise((resolve, reject) => {
                axios.post("/ai/chats/create", data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('setcurrent_chat', response.data.data.chat)
                        commit('setchat_messages', response.data.data.messages)
                        toast.success("New chat started successfully")
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response?.data) {
                        if(error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        } else if(error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                    }
                    reject(error)
                });
            });           
        },

        async get_last_chat({ commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_last_chat')
            return new Promise((resolve, reject) => {
                axios.post("/ai/chats/lastone", data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('setcurrent_chat', response.data.data.chat)
                        commit('setchat_messages', response.data.data.messages)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response?.data) {
                        if(error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        } else if(error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                        }
                    }
                    reject(error)
                });
            });           
        },

        async get_chat_details({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_chat_details')
            return new Promise((resolve, reject) => {
                axios.post(`/ai/chats/${id}`, data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('setcurrent_chat', response.data.data.chat)
                        commit('setchat_messages', response.data.data.messages)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response?.data) {
                        if(error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        } else if(error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                        }
                    }
                    reject(error)
                });
            });           
        },

        async make_chat({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'make_chat')
            return new Promise((resolve, reject) => {
                axios.post(`/ai/chats/message/${id}`, data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('addchat_messages', response.data.data.messages)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response?.data) {
                        if(error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        } else if(error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                    }
                    reject(error)
                });
            });           
        },

        async get_chats({ commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_chats')
            return new Promise((resolve, reject) => {
                axios.post(`/ai/chats?page=${page}`, data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        commit('setchats', response.data.data)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response?.data) {
                        if(error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        } else if(error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                        }
                    }
                    reject(error)
                });
            });           
        },

        async delete_chat({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', `delete_chat${id}`)
            return new Promise((resolve, reject) => {
                axios.post(`/ai/chats/delete/${id}`, data)
                .then(response => {    
                    commit('setloader', false)
                    if(response.data) {
                        comp_play_delete_file('Chat deleted successfully');
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response?.data) {
                        if(error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        } else if(error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                    }
                    reject(error)
                });
            });           
        },

        async chat_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', `chat_status${id}`)
            return new Promise((resolve, reject) => {
                axios.post(`/ai/chats/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        if (data.status == 1) {
                            toast.success("Chat activated successfully")
                        } else {
                            toast.success("Chat deactivated successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response?.data) {
                        if(error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            comp_play_error_file(error.response.data.msg);
                        } else if(error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                            comp_play_error_file(error.response.data.message);
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}